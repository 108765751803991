import './childrenLetters.styles.scss';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import lettersData from '../../utils/lettersData.json';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ChildrenLetters = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % lettersData.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + lettersData.length) % lettersData.length
    );
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (modalIsOpen) {
        if (event.key === 'ArrowRight') {
          nextImage();
        } else if (event.key === 'ArrowLeft') {
          prevImage();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalIsOpen]);

  return (
    <>
      <header className='header'>
        <h2>Scrisori de mulțumire din partea copiilor</h2>
      </header>
      <div className='carousel'>
        {lettersData.map((letter, index) => (
          <img
            key={index}
            src={letter.thumbnail}
            alt={letter.alt}
            onClick={() => openModal(index)}
            className='carousel-image'
            loading='lazy'
          />
        ))}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel='Image Modal'>
          <div className='modal__header'>
            <button onClick={closeModal}>X</button>
          </div>
          <div className='modal__container'>
            <button onClick={prevImage}>˂</button>
            <img
              src={lettersData[currentImageIndex].image}
              alt={lettersData[currentImageIndex].alt}
            />
            <button onClick={nextImage}>˃</button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ChildrenLetters;
