import LinkComponent from '../Link/Link.component';
import './publicatie.styles.scss';

const Publicatie = () => {
  return (
    <section className='publicatie'>
      <div className='publicatie__image'>
        <img
          src='./../images/logos/argesulonline.png'
          alt='Asociația Învață pentru Tine, meditații gratuite pentru copii
          nevoiași. Meditatorii sunt profesori voluntari'
        />
      </div>
      <div className='publicatie__message'>
        <h3>
          "Asociația Învață pentru Tine, meditații gratuite pentru copii
          nevoiași. Meditatorii sunt profesori voluntari"
        </h3>
        <p>✍️ argesulonline.ro</p>

        <LinkComponent
          to={`https://argesulonline.ro/asociatia-invata-pentru-tine-meditatii-gratuite-pentru-copii-nevoiasi-meditatorii-sunt-profesori-voluntari/`}
          classStyle={'right'}
          children={'🔗 Citește articolul'}
          target={'__blank'}
        />
      </div>
    </section>
  );
};

export default Publicatie;
