import './charityBox.styles.scss';

const CharityBox = () => (
  <main className='charity-box'>
    <h1>
      Charity box - pentru a aduce un <span>zâmbet</span> acestor copii
    </h1>
    <div className='charity-box__text'>
      <h2>CE FACEM NOI?</h2>
      <p>
        Susținerea orelor necesare de pregătire în vederea examenelor, sau
        îmbunătățirii nivelului de studiu prin ore individuale de studiu
        focusate pe nevoia elevului.
      </p>
      <p>
        Prevenirea si reducerea abandonului scolar sau marginalizarea copiilor
        cu posibilitati financiare reduse
      </p>
    </div>
    <div className='charity-box__needs'>
      <h3>
        PENTRU A INDEPLINI OBIECTIVELE DE MAI SUS AVEM NEVOIE DE AJUTORUL
        VOSTRU!
      </h3>
      <h4>AVEM NEVOIE DE SPONSORIZARI!</h4>
      <h4>AVEM NEVOIE DE DONATII!</h4>
      <h4>AVEM NEVOIE DE VOI PENTRU PENTRU VIITORUL LOR!</h4>
    </div>
    <h2>CONT PENTRU DONATII:</h2>
    <h3 className='charity-box__iban'>RO07BTRLRONCRT0CS4343901</h3>
  </main>
);

export default CharityBox;
